.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.light-bg {
  background-color: #111218 !important;
  color: #fff;
}

.dark-bg {
  background-color: #000000 !important;
  color: #fff;
}
.content-wrapper {
  background-color: #000000 !important;
  color: #fff;
}

.text-with-bars {
  display: flex;
  align-items: center;
  justify-content: center;
}

.horizontal-bar {
  width: 130px; /* Adjust the width as needed */
  height: 3px; /* Adjust the height as needed */
  background-color: #ffffff; /* Adjust the color as needed */
}

.text {
  padding: 0 10px; /* Add some spacing between the bars and text */
}
/* Hero.css */
.hero-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: left;
  height: 90vh;
}

.hero-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, transparent 10%, black 90%);
  opacity: 100;
}

.hero-content {
  z-index: 1;

  color: white;
  /* Additional styling for your content */
}

.hero-content-wrapper {
  background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent black background */
  padding: 20px;
  border-radius: 10px; /* Optional: Add border-radius for rounded corners */
  backdrop-filter: blur(6px); /* Apply a backdrop blur effect */
}

